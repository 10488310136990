<template lang="html">
  <div class="offlineAddress">
    <img class="cover" src="../../assets/image/xianxuqian.png" alt="">
    <div class="box">
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="left">
          地址{{index + 1}}
        </div>
        <div class="right">
          {{item.address}}
        </div>
      </div>
    </div>
    <div class="btn" @click="$router.go(-1)">
      <van-button color="#f3f9e9" round>
        <span style="color:#85C226;">返回</span>
      </van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'offlineAddress',
    data() {
      return {
        listData: []
      };
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.$require.HttpPost('/h5/contract/contractAddress').then(res => {
          this.listData = res.data.addressList;
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .offlineAddress {
    .cover {
      width: 100%;
      height: 541px;
    }
    .box {
      padding: 0 24px;
      margin-top: 34px;
      .list {
        height: 130px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #85C226;
        border-radius: 65px;
        font-size: 32px;
        color: #000000;
        margin-bottom: 40px;
        .left {
          width: 156px;
          height: 130px;
          line-height: 130px;
          background: #85C226;
          border-radius: 65px;
          text-align: center;
          margin-right: 30px;
        }
        .right {
          flex: 1;
        }
      }
    }
    .btn {
      width: 468px;
      height: 98px;
      margin: 120px auto 0 auto;
    }
  }
</style>
